import { AMaterialLessonBlockTemplate } from "../../models";

const images = ["/images/figma_image_rect.png"];
const text = {
  title: {
    sample: "this is a text title",
    size: "61px",
    color: "var(--primary)",
  },
  subtitle: {
    sample: "this is a text subtitle",
    size: "14px",
    color: "var(--accent)",
  },
  desc: {
    sample: "this is a text description",
    size: "16px",
    color: "black",
  },
};

export const TopicIntro: AMaterialLessonBlockTemplate[] = [
  // topic intro 1
  {
    name: "topic intro 1",
    template: {
      name: "topic intro 1",
      groups: [
        {
          items: [
            {
              type: "image",
              value: images[0],
              style: {
                height: "80%",
              },
            },
            {
              type: "text",
              value: text.desc.sample,
              style: {
                height: "20%",
                // fontSize: "20px",
                color: text.desc.color,
              },
            },
          ],
          style: {
            display: "flex",
            flexDirection: "column",
            gap: "15px",
            width: "55%",
            height: "100%",
          },
        },
        {
          items: [
            {
              type: "text",
              value: text.title.sample,
              style: {
                fontWeight: "bold",
                color: text.title.color,
                fontSize: text.title.size,
                height: "fit-content",
              },
            },
            {
              type: "text",
              value: text.subtitle.sample,
              style: {
                fontWeight: "bold",
                color: text.subtitle.color,
                fontSize: text.subtitle.size,
                height: "100%",
              },
            },
            {
              type: "text",
              value: text.desc.sample,
              style: {
                // fontWeight: "bold",
                color: text.desc.color,
                fontSize: text.desc.size,
                height: "fit-content",
              },
            },
            {
              type: "image",
              value: images[0],
              style: {
                height: "40%",
              },
            },
          ],
          style: {
            display: "flex",
            flexDirection: "column",

            justifySelf: "flex-end",
            alignSelf: "flex-end",
            width: "40%",
            height: "100%",
          },
        },
      ],
      style: {
        display: "flex",
        flexDirection: "row",
        // gap: "60px",
        justifyContent: "space-between",
        width: "100%",
        height: "100%",
      },
    },
  },

  // Topic intro 2:
  {
    name: "Topic intro 2",
    template: {
      name: "Topic intro 2",
      groups: [
        // left side:
        {
          items: [
            {
              type: "text",
              value: text.title.sample,
              style: {
                fontWeight: "bold",
                color: text.title.color,
                fontSize: text.title.size,
                height: "fit-content",
              },
            },
            {
              type: "text",
              value: text.subtitle.sample,
              style: {
                fontWeight: "bold",
                color: text.subtitle.color,
                fontSize: text.subtitle.size,
                height: "100%",
              },
            },
            {
              type: "text",
              value: text.desc.sample,
              style: {
                // fontWeight: "bold",
                color: text.desc.color,
                fontSize: text.desc.size,
                height: "40%",
              },
            },
          ],
          style: {
            gridColumn: "span 2/ span 2",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignSelf: "flex-start",
            justifySelf: "flex-start",
            height: "100%",
          },
        },

        // Right side:
        {
          items: [
            {
              type: "image",
              value: images[0],
              style: {
                width: "100%",
                height: "55%",

                display: "block",
                marginBottom: "20px",
              },
            },
            {
              type: "text",
              value: text.desc.sample,
              style: {
                width: "50%",
                height: "40%",
                position: "absolute",
                bottom: "0px",
                left: "0px",

                color: text.desc.color,
                fontSize: text.desc.size,
                maxWidth: "240px",
              },
            },
            {
              type: "image",
              value: images[0],
              style: {
                width: "50%",
                height: "40%",
                position: "absolute",
                bottom: "0px",
                right: "0px",
              },
            },
          ],
          style: {
            gridColumn: "span 3/ span 3",
            display: "flex",
            height: "100%",
            justifySelf: "flex-start",
            alignSelf: "flex-start",
            justifyContent: "space-between",

            position: "relative",
          },
        },
      ],
      style: {
        display: "grid",
        gridTemplateColumns: "repeat(5, minmax(0, 1fr))",
        gap: "50px",
        width: "100%",
        height: "100%",
      },
    },
  },
];
